<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page

========================================================================================== -->

<template>
  <div id="page-user-view">
    <vs-alert v-if="errorOccurred" color="danger" title="Domain Not Found">
      <span>Domain record with id: not found. </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'admin-list-flashcard-domain' }" class="text-inherit underline">All Domains</router-link>
      </span>
    </vs-alert>

    <div v-if="domainData" id="domain-data">
      <!--- TOP DOMAIN BANNER START --->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <div
            class="rounded-lg w-full main-border"
            style="background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png)"
          >
            <div class="knowledge-base-jumbotron-content rounded-lg lg:p-8 sm:p-8 p-6">
              <div class="flex gap-6 items-center">
                <img :src="domainData.thumbnail" v-if="domainData.thumbnail" class="rounded-lg" width="125px" />
                <div>
                  <h5 class="font-light text-white ml-2">Study Admin</h5>
                  <h1 class="text-white font-bold" style="font-size: 3.5rem">{{ domainData.name }}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--- TOP DOMAIN BANNER END --->

      <!--- DOMAIN ACTION BAR START --->
      <div class="vx-row mb-6 match-height">
        <div class="vx-col grid grid-cols-3 gap-8 w-full">
          <vx-card
            class="p-6 cursor-pointer"
            @click="$router.push(`/a/study`)"
            :style="`background-image: linear-gradient(118deg, rgba(var(--vs-primary), 0.4), rgba(var(--vs-primary), .2)), url(${cardBgImgURL})`"
          >
            <div class="flex items-center justify-center">
              <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-16 w-16" />
            </div>
            <div class="flex items-center justify-center">
              <h2 class="text-center mt-4 text-white">Back To<br />Study Manager</h2>
            </div>
          </vx-card>
          <vx-card
            class="p-6 cursor-pointer"
            @click="editDomainPopup = !editDomainPopup"
            :style="`background-image: linear-gradient(118deg, rgba(var(--vs-success), 0.4), rgba(var(--vs-success), .2)), url(${cardBgImgURL})`"
          >
            <div class="flex items-center justify-center">
              <feather-icon icon="EditIcon" svgClasses="h-16 w-16" />
            </div>
            <div class="flex items-center justify-center">
              <h2 class="text-center mt-4 text-white">Edit<br />Domain</h2>
            </div>
          </vx-card>
          <vx-card
            class="p-6 cursor-pointer text-white"
            @click="confirmDeleteDomain"
            :style="`background-image: linear-gradient(118deg, rgba(var(--vs-danger), 0.4), rgba(var(--vs-danger), .2)), url(${cardBgImgURL})`"
          >
            <div class="flex items-center justify-center">
              <feather-icon icon="TrashIcon" svgClasses="h-16 w-16" />
            </div>
            <div class="flex items-center justify-center">
              <h2 class="text-center mt-4 text-white">Archive<br />Domain</h2>
            </div>
          </vx-card>
        </div>
      </div>
      <!--- DOMAIN ACTION BAR END --->

      <!--- DOMAIN SHOW INFORMATION START--->
      <div class="vx-row mb-6 match-height">
        <div class="vx-col w-1/3">
          <vx-card class="w-full bg-dark flex items-center">
            <h3 class="font-bold">Domain Id</h3>
            <h3 class="font-light">{{ domainData.id }}</h3>
          </vx-card>
        </div>
        <div class="vx-col w-1/3">
          <vx-card class="w-full bg-dark flex items-center">
            <h3 class="font-bold">Domain Status</h3>
            <h3 class="capitalize font-light">{{ domainData.status }}</h3>
          </vx-card>
        </div>
        <div class="vx-col w-1/3">
          <vx-card class="w-full bg-dark flex items-center">
            <h3 class="font-bold">Domain Sets</h3>
            <h3 class="capitalize font-light">{{ domainData.sets.length }}</h3>
          </vx-card>
        </div>
      </div>
      <!--- DOMAIN SHOW INFORMATION END--->

      <!--- DOMAIN SHOW BUILDER START --->
      <build-domain :domain-data="domainData" />
    </div>
    <div class="simple-spinner" v-else>
      <span></span>
    </div>
    <vs-popup :active.sync="editDomainPopup" title="Edit Domain">
      <div class="popup-input" v-if="domainData">
        <p class="mb-1 font-semibold">Name</p>
        <vs-input v-model="domainData.name" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Description</p>
        <vs-textarea v-model="domainData.description" height="100px" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Thumbnail</p>
        <vs-input v-model="domainData.thumbnail" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Status</p>
        <div class="flex items-center mb-3">
          <vs-radio v-model="domainData.status" vs-name="courseStatus" vs-value="active" class="mr-4">Active </vs-radio>
          <vs-radio v-model="domainData.status" vs-name="courseStatus" vs-value="disabled" class="mr-4">Disabled </vs-radio>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import BuildDomain from '../domain-edit/BuildDomain';

export default {
  data() {
    return {
      cardBgImgURL: 'https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/blue-wave-bg.png',

      editDomainPopup: false,

      domainData: null,
      domainDescriptionCounter: false,
      errorOccurred: false,
    };
  },
  components: {
    Prism,
    vSelect,
    Datepicker,
    BuildDomain,
  },
  methods: {
    fetchDomainDetails(id) {
      this.$http
        .get(`study/domain/${id}?populate=sets`)
        .then((response) => {
          if (response.status === 200) {
            this.domainData = response.data;
          }
        })
        .catch(() => {
          this.errorOccurred = true;
        });
    },
    confirmDeleteDomain() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Archive',
        text: 'Please confirm you want to archive this domain.',
        accept: this.deleteDomain,
        acceptText: 'Archive',
      });
    },
    deleteDomain() {
      this.$vs.loading();

      this.$http
        .delete(`domains/${this.$route.params.domainId}`)
        .then((response) => {
          this.$vs.loading.close();

          if (response.status === 200) {
            this.$router.push('/a/study/domains');

            return this.$vs.notify({
              color: 'success',
              title: 'Domain Archived',
              text: 'You have successfully archived this domain',
              icon: 'feather',
              iconPack: 'icon-check-circle',
              position: 'top-right',
            });
          }
        })
        .catch((exception) => {
          this.handleError(exception, 'Failed To Delete Domain');
        });
    },
    updateDomain() {
      const pl = {
        ...this.domainData,
        sets: undefined,
      };
      this.$http
        .patch(`study/domain/${this.domainData.id}`, pl)
        .then((response) => {
          if (response.status === 200) {
            this.$vs.notify({
              title: 'Domain Updated Successfully',
              text: `${this.domainData.name} has been updated.`,
              color: 'success',
              position: 'bottom-right',
              iconPack: 'feather',
              icon: 'icon-check-circle',
            });
          }
        })
        .catch((exception) => {
          this.handleError(exception, 'Failed To Update Domain');
        });
    },
    handleError(exception, title, text) {
      let error = 'An unknown error occurred while domain update';
      if (exception.response) {
        error = exception.response.data.message;
      }
      if (text) {
        error = text;
      }
      return this.$vs.notify({
        title,
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
  created() {
    this.fetchDomainDetails(this.$route.params.domainId);
  },
  watch: {
    editDomainPopup(val) {
      if (!val) this.updateDomain();
    },
  },
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1700px) {
    .image-domain {
      display: none;
    }
    .domain-col {
      width: 0 !important;
      min-height: 2.05vh;
    }
    .welcome-col {
      width: 100% !important;
    }
  }
}
</style>
